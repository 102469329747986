import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";

import ChevronDown from "../../assets/icons/chevron-down.svg";
import DeprecatedH4 from "components/deprecated/DeprecatedH4";

const Title = styled(DeprecatedH4)`
  && {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #121314;
    margin: 0;
  }
`;

const Subtitle = styled.span`
  && {
    font-size: 12px;
    line-height: 15px;
    color: #111317;
    margin: 0;
  }
`;

const Button = styled.button<{ expanded: boolean }>`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    padding: 8px 16px;
    margin: 16px 0;
    border: 1px solid var(--gray10);
    background: #ffffff;
    font-weight: 600;
    line-height: 20px;
    color: #5c5e6a;
    text-align: left;
  }
`;

export const Chevron = styled(ChevronDown)<{ $expanded: boolean }>`
  margin-left: -8px;
  margin-top: -2px;
  margin-right: 0px;
  height: 15px;
  transform: ${({ $expanded }) => ($expanded ? "rotate(180deg)" : "none")};
  transition: transform 0.2s;
`;

interface Props {
  children: JSX.Element[];
  title?: string;
  subtitle?: string;
  expandAll?: boolean;
  className?: string;
}

const ParameterRowAccordion = ({ children, title, subtitle, expandAll, className }: Props) => {
  const [expanded, setExpanded] = useState(false);

  if (expandAll) {
    return (
      <>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </>
    );
  }

  // Note: Using margins and/or padding in HTML elements involved in the "Collapse" process
  // will result in a buggy "jitter" effect on expansion. Eliminating margins and padding and using a
  // "translateY" tranform eliminated that jitter in this case.
  return (
    <div className={className}>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <div style={{ marginBottom: "0", padding: "0" }}>{children.slice(0, 3)}</div>
      <Collapse in={expanded}>
        <div style={{ margin: "0", padding: "0" }}>{children.slice(3)}</div>
      </Collapse>
      {children.length > 3 && (
        <div style={{ marginTop: "0", transform: "translateY(16px)" }}>
          <hr style={{ marginTop: "0" }} />
          <Button
            className="text-base text-gray-70"
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <Chevron $expanded={expanded} />
              {expanded ? <>Collapse</> : <>{`Show ${children.length - 3} more`}</>}
            </b>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ParameterRowAccordion;
